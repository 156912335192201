.gallery_sec {
    position: relative;
    padding-top: 200 / $setFs + rem;
    padding-bottom: 130 / $setFs + rem;
    z-index: 1;

    &.dark {
        padding-top: 130 / $setFs + rem;
        padding-bottom: 80 / $setFs + rem;

        .gallery_prev,
        .gallery_next {

            // color: #fff;
            // font-weight: normal;
            // border: 1 / $setFs + rem solid #fff;
            // box-sizing: border-box;
            &::before,
            &::after {
                background: #4d4c4c;
            }

            &:hover,
            &:focus {

                &::before,
                &::after {
                    background: #fff;
                }
            }
        }

        .more_txt {
            color: #fff;
        }

        .fa-angle-right {
            color: #fff;
            transition-delay: .15s;
        }

        .more_link {
            &::after {
                border-color: #fff;
            }
        }
    }

    .more_row {
        margin-top: 84 / $setFs + rem;

        @media screen and (max-width: 950px) {
            margin-top: 0;
        }

        @media screen and (max-width: 480px) {
            margin-top: 32 / $setFs + rem;
        }
    }
}

.gallery_container {
    height: 544 / $setFs + rem;
    position: relative;
    margin-top: 50 / $setFs + rem;

    @media screen and (max-width: 950px) {
        height: 60vw;
        overflow: hidden;
    }

    @media screen and (max-width: 480px) {
        height: 81vw;
    }

    .container {
        max-width: 1680 / $setFs + rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.gallery_spinner {
    width: 230vw;
    height: 230vw;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -115vw;

    @media screen and (max-width: 950px) {
        width: 400vw;
        height: 380vw;
        margin-left: -200vw;
    }

    @media screen and (max-width: 480px) {
        width: 600vw;
        height: 570vw;
        margin-left: -300vw;
    }
}

.gallery_item {
    width: 398/(1920*2.3)*100%;
    height: 50%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin-left: -398/2 / (1920*2.3)*100%;
    transform-origin: center bottom;

    &:nth-child(1) {
        transform: rotate(-30deg);
    }

    &:nth-child(2) {
        transform: rotate(-15deg);
    }

    &:nth-child(3) {
        transform: rotate(0deg);
    }

    &:nth-child(4) {
        transform: rotate(15deg);
    }

    &:nth-child(5) {
        transform: rotate(30deg);
    }
}

.gallery_prev,
.gallery_next {
    width: 120 / $setFs + rem;
    height: 120 / $setFs + rem;
    border-radius: 120 / $setFs + rem;
    // font-family: $font1;
    // font-weight: bold;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba($color, .1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &::before,
    &:after {
        content: '';
        width: 40%;
        height: 3 / $setFs + rem;
        background: #000;
        background: #bbb;
        position: absolute;
        left: 30%;
        transition: background .2s ease-out;
    }

    &:hover,
    &:focus {

        &::before,
        &:after {
            background: #4d4d4d;
        }
    }
}

.gallery_prev {
    &::before {
        top: 50%;
        transform-origin: left top;
        transform: rotate(-45deg);
    }

    &::after {
        bottom: 50%;
        transform-origin: left bottom;
        transform: rotate(45deg);
    }
}

.gallery_next {
    &::before {
        top: 50%;
        transform-origin: right top;
        transform: rotate(45deg);
    }

    &::after {
        bottom: 50%;
        transform-origin: right bottom;
        transform: rotate(-45deg);
    }
}

.gallery_prev {
    left: 0;
}

.gallery_next {
    right: 0;
}

.gallery_btn_link {
    flex: 1;
    height: 100%;
    @include acc;

    &::after {
        border-color: #fff;
    }
}

.gallery_bg {
    width: 100vw;
    height: 100vw;
    border-radius: 100vw;
    background: $color;
    position: fixed;
    top: 100%;
    left: 50%;
    transform: scale(0);
    margin-left: -50vw;
    margin-top: -20vw;
    z-index: 1;

    @media screen and (max-width: 950px) {
        width: 100vh;
        height: 100vh;
        margin-left: -50vh;
        margin-top: -30vh;
    }
}

.gallery_link {
    @include acc;

    &::after {
        border-color: #fff;
    }
}