//  @font-face {
//      font-family: 'NotoSerifTC';
//      src: url('../../fonts/NotoSerifTC-Light.otf') format('truetype');
//      font-display: swap;
//  }

// @font-face {
//     font-family: 'Helvetica';
//     //  src: url('../../fonts/AGaramond-Regular.otf') format('truetype');
//     src: url('../../fonts/helvetica-light.ttf') format('truetype');
//     font-display: swap;
// }

@font-face {
    font-family: 'EBGaramond';
    src: url('../../fonts/EBGaramond-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    src: url('../../fonts/GenWanMin-SB.woff2') format('woff2'),
        url('../../fonts/GenWanMin-SB.ttf') format('truetype');
    font-family: 'GenWanMin-SB';
    font-display: swap;
}

// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;700&display=swap');

$color: #1c1a1d;
$color2: #e9e9e9;
$color3: #51019c;
$color4: #ab272a;
$color5: #f8df0a;
$color6: #070fbe;
$color7: #085596;
$color8: #026235;
$gray: #e9e9e9;
$setFs: 16;
$txtColor: #1c1a1d;
$font: "微軟正黑體",
    "蘋果儷黑體",
    Arial,
    sans-serif,
    Verdana;
// $font1: 'AGaramond',
// 'NotoSerifTC',
// $font;
$font1: 'GenWanMin-SB',
    $font;


@mixin word_limit($line: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin _w {
    @media screen and (min-width: 951px) {
        &:after {
            border-color: #fff;
        }
    }
}

@mixin acc {
    @media screen and (min-width: 951px) {
        &:after {
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border: 2px dotted $color4;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:after {
                opacity: 1;
            }
        }
    }
}

@mixin acc_before {
    @media screen and (min-width: 951px) {
        &:before {
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border: 2px dotted $color4;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:before {
                opacity: 1;
            }
        }
    }
}

@mixin acc_w {
    @include acc;
    @include _w;
}

@mixin acc_before_w {
    @include acc_before;
    @include _w;
}


@mixin clear {
    clear: both;
}

@mixin clearfix {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


@mixin inblock($spacing: 0) {
    font-size: 0;
    letter-spacing: 0;

    >* {
        display: inline-block;
        vertical-align: top;
        letter-spacing: $spacing / $setFs + rem;
    }
}

@mixin buttonApp($color) {
    color: #fff;
    text-shadow: 0 / $setFs + rem 0 / $setFs + rem 0 darken($color, 0%);
    box-sizing: border-box;
    background: $color;
    border: 1 / $setFs + rem solid darken($color, 1%);
    border-right: 1 / $setFs + rem solid darken($color, 13%);
    border-bottom: 2 / $setFs + rem solid darken($color, 18%);
    box-shadow: 1 / $setFs + rem 1 / $setFs + rem 4 / $setFs + rem rgba(darken($color, 5%), .8);
    border-radius: 1 / $setFs + rem;
    transition: all 0.05s ease-out;
}

@mixin buttonApp_hvr($color) {
    color: lighten($color, 40%);
    text-shadow: -1 / $setFs + rem -1 / $setFs + rem 0 darken($color, 22%);
    background: $color;
    background: lighten($color, 3%);
    border: 1 / $setFs + rem solid rgba(0, 0, 0, 0.05);
    border-bottom: 2 / $setFs + rem solid transparent;
    box-shadow: 1 / $setFs + rem 1 / $setFs + rem 2 / $setFs + rem rgba(darken($color, 5%), .8);
    transition: all 0.05s linear;
}