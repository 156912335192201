.more_row {
    display: flex;
    justify-content: center;
}

.more_btn {
    .fa-angle-right {
        margin-left: 7px;
        position: relative;
        bottom: -2px;
    }
}

.more_link {
    display: flex;
    font-family: $font1;
    padding: 20px 0;
    @include acc;

    &:focus,
    &:hover {
        .more_circle {
            transform: translate(175%, -50%);
            transform: translate(79%, -50%);
        }

        .more_mask_txt {
            transform: translate(-85%, -50%);
            transform: translate(-75%, -50%);
        }

        .fa-angle-right {
            color: #fff;
            transition-delay: .05s;
        }

    }
}

.more_circle {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background: #ab272a;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-30%, -50%);
    overflow: hidden;
    transition: transform .5s cubic-bezier(0.25, 1, 0.5, 1);
}

.more_mask_txt {
    color: #fff;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    transform: translate(15%, -50%);
    transform: translate(27%, -50%);
    transition: transform .5s cubic-bezier(0.25, 1, 0.5, 1);
}

.more_txt,
.more_mask_txt {
    line-height: 22px;
}