.form_row {
    display: flex;
    margin-left: -10 / $setFs + rem;
    margin-right: -10 / $setFs + rem;

    @media screen and (max-width: 950px) {
        flex-wrap: wrap;
        margin-left: -9 / $setFs + rem;
        margin-right: -9 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-right: 0;
    }

    .button {
        line-height: 65 / $setFs + rem;
        position: relative;
        @include acc;
    }
}

.form_col {
    flex: 1;
    // display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10 / $setFs + rem;
    position: relative;

    @media screen and (max-width: 950px) {
        padding: 7 / $setFs + rem 9 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        flex: 0 0 100%;
    }

    &.short {
        flex: 0 0 10%;

        @media screen and (max-width: 950px) {
            flex: 0 0 25%;
        }
    }

    &.auto {
        flex: 0 0 auto;

        @media screen and (max-width: 480px) {
            padding: 5 / $setFs + rem 9 / $setFs + rem;
        }
    }
}

.form_title {
    flex: 1;
    font-family: $font1;
    font-weight: bold;
    font-size: 18 / $setFs + rem;
    text-align: right;
    padding: 6 / $setFs + rem;

    @media screen and (max-width: 480px) {
        text-align: left;
        padding-bottom: 0;
    }
}

.column_row {
    display: flex;
    flex-wrap: wrap;
    margin: -0.375rem;
}

.column_col {
    min-width: 0;
    flex: 1;
    padding: 6 / $setFs + rem 10 / $setFs + rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;

    &.col_3 {
        min-width: 0;
        flex: 0 0 33.3333%;
    }

    &.col_auto {
        flex: 0 0 auto;
    }

    .langSwitch {
        height: 100%;

        textarea.form-control {
            height: 100%;
        }
    }
}

.form_check {
    display: flex;
    align-items: center;
}

.form_check_label {
    font-size: 17 / $setFs + rem;
    margin-left: 7 / $setFs + rem;
    cursor: pointer;
    transition: color .2s ease-out;

    &:hover {
        color: $color7;
        font-weight: bold;
    }
}

.form_check_title {
    font-size: 18 / $setFs + rem;
    font-weight: bold;
}