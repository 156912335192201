$alertColor: rgba(211, 46, 94, 0.1);

.inputStyle {
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: 17 / $setFs + rem;
    font-family: $font;
    color: $txtColor;
    padding: 16 / $setFs + rem 22 / $setFs + rem 13 / $setFs + rem;
    line-height: (22 / $setFs);
    box-shadow: none;
    background: #f4f4f4;
    appearance: none;
    box-sizing: border-box;
    display: block;


    &::-webkit-calendar-picker-indicator {
        display: none !important;
    }

    &::-webkit-input-placeholder {
        color: $txtColor;
        font-size: 17 / $setFs + rem;
    }

    &::-moz-placeholder {
        color: $txtColor;
        font-size: 17 / $setFs + rem;
    }

    &:-ms-input-placeholder {
        color: $txtColor;
        font-size: 17 / $setFs + rem;
    }

    &:-moz-placeholder {
        color: $txtColor;
        font-size: 17 / $setFs + rem;
    }

    &:required {
        // border-left: 3 / $setFs + rem solid $color;
    }

    &:focus {
        outline: 1 / $setFs + rem solid $color;
    }

    &.auto {
        width: auto;
    }
}

$calcW: 17 / $setFs+rem;

.selectStyle {
    width: 100%;
    border: 0;
    border-radius: 2 / $setFs + rem;
    font-size: 17 / $setFs + rem;
    font-family: $font;
    color: $txtColor;
    padding: 16 / $setFs + rem 22 / $setFs + rem 11 / $setFs + rem;
    line-height: (22 / $setFs);
    box-shadow: none;
    appearance: none;
    box-sizing: border-box;
    display: block;
    background: url("../../img/icon_arrow_select.png") no-repeat calc(100% - #{$calcW}) center #f4f4f4;
    background-size: 13 / $setFs + rem auto;

    &:focus {
        outline: 1 / $setFs + rem solid $color;
    }
}

.radioStyle {
    flex: 0 0 16 / $setFs + rem;
    width: 16 / $setFs + rem;
    height: 16 / $setFs + rem;
    padding: 0;
    margin: 0;
    position: relative;
    background: transparent;
    border: 0;
    border-radius: 16 / $setFs + rem;
    box-sizing: border-box;
    appearance: none;

    &:checked {
        &:before {
            opacity: 1;
        }
    }

    &:before {
        content: "";
        width: 6 / $setFs + rem;
        height: 6 / $setFs + rem;
        background: #666;
        border-radius: 10 / $setFs + rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        @media screen and (max-width: 950px) {
            width: 8 / $setFs + rem;
            height: 8 / $setFs + rem;
        }
    }

    &:focus {
        outline: 1 / $setFs + rem solid $color;
    }
}

.checkboxStyle {
    flex: 0 0 20 / $setFs + rem;
    width: 20 / $setFs + rem;
    height: 20 / $setFs + rem;
    padding: 0;
    margin: 0;
    position: relative;
    background: #f4f4f4;
    border: 2px solid darken(#f4f4f4, 5%);
    border-radius: 2 / $setFs + rem;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;
    top: 1px;

    @media screen and (max-width: 950px) {
        flex: 0 0 20 / $setFs + rem;
        width: 20 / $setFs + rem;
        height: 20 / $setFs + rem;
    }

    &:checked {
        &:before {
            opacity: 1;
        }
    }

    &:before {
        content: "";
        width: 8 / $setFs + rem;
        height: 8 / $setFs + rem;
        background: $txtColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        @media screen and (max-width: 950px) {
            width: 10 / $setFs + rem;
            height: 10 / $setFs + rem;
        }
    }

    &:focus {
        outline: 1 / $setFs + rem solid $color;
    }

    &~label {
        line-height: 1.5;
        padding: 2 / $setFs + rem 0;
    }
}

.buttonStyle {
    width: 222 / $setFs + rem;
    background: #fff;
    color: #1c1920;
    text-align: center;
    line-height: 42 / $setFs + rem;
    border: 0;
    border-radius: 0;
    appearance: none;
    transition: background 0.2s ease-out;

    a {
        line-height: 48 / $setFs + rem;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.6);
    }

    &:focus {
        outline: 1 / $setFs + rem solid $color;
    }

    &.cancel {
        background: rgba(255, 255, 255, 0.15);
    }

    &.submit {
        background: $color;
    }

    &.goback {
        background: $color;
        color: #fff;
    }
}