.hashtags_sec {
    background: $color;
    // padding: 50 / $setFs + rem 0 95 / $setFs + rem;
    padding: 65 / $setFs + rem 0 65 / $setFs + rem;

    .container {
        max-width: 1600 / $setFs + rem;
    }
}

.hashtags_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 18 / $setFs + rem;
}

.hashtags_li {
    padding: 15 / $setFs + rem 8 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 10 / $setFs + rem 8 / $setFs + rem;
    }
}

.hashtags_link {
    font-size: 15 / $setFs + rem;
    color: #fff;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20 / $setFs + rem;
    border: 1px solid rgba(255, 255, 255, 0);
    line-height: 40 / $setFs + rem;
    padding: 0 25 / $setFs + rem;
    box-sizing: border-box;
    @include acc;

    &::after {
        border-color: #fff;
    }

    &:hover {
        color: #fff;
        background: transparent;
        border-color: #ab272a;
    }

    &.color_0 {
        &:hover {
            border-color: #fff;
        }
    }

    &.color_1 {
        &:hover {
            border-color: #ab272a;
        }
    }

    &.color_2 {
        &:hover {
            border-color: #f8df0a;
        }
    }

    &.color_3 {
        &:hover {
            border-color: #070fbe;
        }
    }

    &.color_4 {
        &:hover {
            border-color: #085596;
        }
    }

    &.color_5 {
        &:hover {
            border-color: #026235;
        }
    }
}