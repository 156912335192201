.arti_list {
    margin-bottom: 15 / $setFs + rem;
}

.arti_li {
    padding: 10 / $setFs + rem 0;
}

.arti_link {
    @include acc;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba($color, 0.1);
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        transition: width .4s cubic-bezier(0.25, 1, 0.5, 1);
    }

    &:focus,
    &:hover {
        &::before {
            width: 100vw;
        }

        &:hover,
        &:focus {
            .arti_label {

                &.color_1 {
                    &::after {
                        background-image: url('../../img/label_1.png');
                    }

                    .arti_label_txt {
                        background: $color4;
                    }
                }

                &.color_2 {
                    &::after {
                        background-image: url('../../img/label_2.png');
                    }

                    .arti_label_txt {
                        background: $color5;
                    }
                }

                &.color_3 {
                    &::after {
                        background-image: url('../../img/label_3.png');
                    }

                    .arti_label_txt {
                        background: $color6;
                    }
                }

                &.color_4 {
                    &::after {
                        background-image: url('../../img/label_4.png');
                    }

                    .arti_label_txt {
                        background: $color7;
                    }
                }

                &.color_5 {
                    &::after {
                        background-image: url('../../img/label_5.png');
                    }

                    .arti_label_txt {
                        background: $color8;
                    }
                }
            }
        }
    }
}

.arti_row {
    display: flex;
}

.arti_left {
    width: 57 / $setFs + rem;
    flex: 0 0 57 / $setFs + rem;
    padding-left: 25 / $setFs + rem;
    padding-bottom: 10 / $setFs + rem;
    box-sizing: border-box;
}

.arti_middle {
    min-width: 0;
    flex: 1;
    padding: 25 / $setFs + rem 40 / $setFs + rem 20 / $setFs + rem;
    display: flex;

    @media screen and (max-width: 480px) {
        padding-left: 20 / $setFs + rem;
        padding-right: 20 / $setFs + rem;
    }
}

.arti_right {
    width: 170 / $setFs + rem;
    flex: 0 0 170 / $setFs + rem;
    padding: 20 / $setFs + rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        width: 1px;
        height: calc(100% - #{40 / $setFs + rem});
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.arti_author {
    font-size: (17 + 5) / $setFs + rem;
    font-family: $font1;
    text-align: center;
}

.arti_author_en {
    font-size: (15 + 5) / $setFs + rem;
    font-family: $font1;
    text-align: center;
}

.arti_label {
    padding-bottom: 10 / $setFs + rem;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 10 / $setFs + rem;
        background-image: url('../../img/label.png');
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: background-image .2s ease-out;
    }
}

.arti_label_txt {
    width: 100%;
    min-height: 116 / $setFs + rem;
    text-align: center;
    font-size: 17 / $setFs + rem;
    font-family: $font1;
    color: #fff;
    writing-mode: vertical-lr;
    letter-spacing: 1 / $setFs + rem;
    line-height: 1;
    background: $color;
    padding: 30 / $setFs + rem 0 27 / $setFs + rem 8 / $setFs + rem;
    box-sizing: border-box;
    transition: background .2s ease-out;
}

.arti_subtitle {
    font-size: (17 + 3) / $setFs + rem;
    font-family: $font1;
    margin-bottom: 3 / $setFs + rem;
}

.arti_title {
    font-size: (22 + 7) / $setFs + rem;
    font-family: $font1;
}

.arti_txt {
    line-height: (30/18);
    @include word_limit(2);
    margin-top: 3 / $setFs + rem;
}

.arti_uid {
    line-height: (30/18);
    font-family: $font1;
    margin-top: 3 / $setFs + rem;
}

.arti_tag {
    color: #fff;
    font-size: 13 / $setFs + rem;
    font-family: $font1;
    background-color: $color4;
    padding: 2px 10px 1px;
    border-radius: 10px;
    margin-left: 5px;
    position: relative;
    top: -2px;
}