@charset "UTF-8";
@font-face {
  font-family: "EBGaramond";
  src: url("../../fonts/EBGaramond-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  src: url("../../fonts/GenWanMin-SB.woff2") format("woff2"), url("../../fonts/GenWanMin-SB.ttf") format("truetype");
  font-family: "GenWanMin-SB";
  font-display: swap;
}
@media screen and (min-width: 951px) {
  .swiper-slide a:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .swiper-slide a:focus:after {
    opacity: 1;
  }
}
.swiper-slide a::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-button-prev,
.swiper-button-next {
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 6.25rem;
  transition: opacity 0.2s ease-out;
  margin-top: -3.375rem;
  opacity: 0.6;
}
@media screen and (min-width: 951px) {
  .swiper-button-prev:after,
.swiper-button-next:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .swiper-button-prev:focus:after,
.swiper-button-next:focus:after {
    opacity: 1;
  }
}
@media screen and (max-width: 950px) {
  .swiper-button-prev,
.swiper-button-next {
    opacity: 1;
  }
}
@media screen and (max-width: 480px) {
  .swiper-button-prev,
.swiper-button-next {
    width: 5.625rem;
    height: 5.625rem;
    margin-top: -2.8125rem;
  }
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 0;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.1;
}

.swiper-button-prev {
  background-image: url("../../img/swiper_btn_prev.png");
  background-size: contain;
  background-position: center center;
  margin-left: -1.5rem;
  left: 1.875rem;
}
@media screen and (max-width: 950px) {
  .swiper-button-prev {
    left: 1.25rem;
  }
}
@media screen and (max-width: 480px) {
  .swiper-button-prev {
    left: 0.625rem;
  }
}

.swiper-button-next {
  background-image: url("../../img/swiper_btn_next.png");
  background-size: contain;
  background-position: center center;
  margin-right: -1.5rem;
  right: 1.875rem;
}
@media screen and (max-width: 950px) {
  .swiper-button-next {
    right: 1.25rem;
  }
}
@media screen and (max-width: 480px) {
  .swiper-button-next {
    right: 0.625rem;
  }
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #222;
  background-color: transparent;
  box-sizing: border-box;
  margin: 0 0.625rem !important;
  transition: all 0.3s ease-out;
  opacity: 1;
  position: relative;
}
@media screen and (min-width: 951px) {
  .swiper-pagination-bullet:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .swiper-pagination-bullet:focus:after {
    opacity: 1;
  }
}
@media screen and (max-width: 480px) {
  .swiper-pagination-bullet {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.swiper-pagination-bullet-active {
  background: #222;
  opacity: 1;
}

.more_row {
  display: flex;
  justify-content: center;
}

.more_btn .fa-angle-right {
  margin-left: 7px;
  position: relative;
  bottom: -2px;
}

.more_link {
  display: flex;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  padding: 20px 0;
}
@media screen and (min-width: 951px) {
  .more_link:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .more_link:focus:after {
    opacity: 1;
  }
}
.more_link:focus .more_circle, .more_link:hover .more_circle {
  transform: translate(175%, -50%);
  transform: translate(79%, -50%);
}
.more_link:focus .more_mask_txt, .more_link:hover .more_mask_txt {
  transform: translate(-85%, -50%);
  transform: translate(-75%, -50%);
}
.more_link:focus .fa-angle-right, .more_link:hover .fa-angle-right {
  color: #fff;
  transition-delay: 0.05s;
}

.more_circle {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: #ab272a;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-30%, -50%);
  overflow: hidden;
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.more_mask_txt {
  color: #fff;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
  transform: translate(15%, -50%);
  transform: translate(27%, -50%);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.more_txt,
.more_mask_txt {
  line-height: 22px;
}

.form_row {
  display: flex;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
@media screen and (max-width: 950px) {
  .form_row {
    flex-wrap: wrap;
    margin-left: -0.5625rem;
    margin-right: -0.5625rem;
  }
}
@media screen and (max-width: 480px) {
  .form_row {
    margin-left: 0;
    margin-right: 0;
  }
}
.form_row .button {
  line-height: 4.0625rem;
  position: relative;
}
@media screen and (min-width: 951px) {
  .form_row .button:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .form_row .button:focus:after {
    opacity: 1;
  }
}

.form_col {
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  padding: 0.625rem;
  position: relative;
}
@media screen and (max-width: 950px) {
  .form_col {
    padding: 0.4375rem 0.5625rem;
  }
}
@media screen and (max-width: 480px) {
  .form_col {
    flex: 0 0 100%;
  }
}
.form_col.short {
  flex: 0 0 10%;
}
@media screen and (max-width: 950px) {
  .form_col.short {
    flex: 0 0 25%;
  }
}
.form_col.auto {
  flex: 0 0 auto;
}
@media screen and (max-width: 480px) {
  .form_col.auto {
    padding: 0.3125rem 0.5625rem;
  }
}

.form_title {
  flex: 1;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: right;
  padding: 0.375rem;
}
@media screen and (max-width: 480px) {
  .form_title {
    text-align: left;
    padding-bottom: 0;
  }
}

.column_row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem;
}

.column_col {
  min-width: 0;
  flex: 1;
  padding: 0.375rem 0.625rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
}
.column_col.col_3 {
  min-width: 0;
  flex: 0 0 33.3333%;
}
.column_col.col_auto {
  flex: 0 0 auto;
}
.column_col .langSwitch {
  height: 100%;
}
.column_col .langSwitch textarea.form-control {
  height: 100%;
}

.form_check {
  display: flex;
  align-items: center;
}

.form_check_label {
  font-size: 1.0625rem;
  margin-left: 0.4375rem;
  cursor: pointer;
  transition: color 0.2s ease-out;
}
.form_check_label:hover {
  color: #085596;
  font-weight: bold;
}

.form_check_title {
  font-size: 1.125rem;
  font-weight: bold;
}

.inputStyle {
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 1.0625rem;
  font-family: "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  color: #1c1a1d;
  padding: 1rem 1.375rem 0.8125rem;
  line-height: 1.375;
  box-shadow: none;
  background: #f4f4f4;
  appearance: none;
  box-sizing: border-box;
  display: block;
}
.inputStyle::-webkit-calendar-picker-indicator {
  display: none !important;
}
.inputStyle::-webkit-input-placeholder {
  color: #1c1a1d;
  font-size: 1.0625rem;
}
.inputStyle::-moz-placeholder {
  color: #1c1a1d;
  font-size: 1.0625rem;
}
.inputStyle:-ms-input-placeholder {
  color: #1c1a1d;
  font-size: 1.0625rem;
}
.inputStyle:-moz-placeholder {
  color: #1c1a1d;
  font-size: 1.0625rem;
}
.inputStyle:focus {
  outline: 0.0625rem solid #1c1a1d;
}
.inputStyle.auto {
  width: auto;
}

.selectStyle {
  width: 100%;
  border: 0;
  border-radius: 0.125rem;
  font-size: 1.0625rem;
  font-family: "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  color: #1c1a1d;
  padding: 1rem 1.375rem 0.6875rem;
  line-height: 1.375;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  display: block;
  background: url("../../img/icon_arrow_select.png") no-repeat calc(100% - 1.0625rem) center #f4f4f4;
  background-size: 0.8125rem auto;
}
.selectStyle:focus {
  outline: 0.0625rem solid #1c1a1d;
}

.radioStyle {
  flex: 0 0 1rem;
  width: 1rem;
  height: 1rem;
  padding: 0;
  margin: 0;
  position: relative;
  background: transparent;
  border: 0;
  border-radius: 1rem;
  box-sizing: border-box;
  appearance: none;
}
.radioStyle:checked:before {
  opacity: 1;
}
.radioStyle:before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background: #666;
  border-radius: 0.625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
@media screen and (max-width: 950px) {
  .radioStyle:before {
    width: 0.5rem;
    height: 0.5rem;
  }
}
.radioStyle:focus {
  outline: 0.0625rem solid #1c1a1d;
}

.checkboxStyle {
  flex: 0 0 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  margin: 0;
  position: relative;
  background: #f4f4f4;
  border: 2px solid #e7e7e7;
  border-radius: 0.125rem;
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  top: 1px;
}
@media screen and (max-width: 950px) {
  .checkboxStyle {
    flex: 0 0 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
.checkboxStyle:checked:before {
  opacity: 1;
}
.checkboxStyle:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background: #1c1a1d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
@media screen and (max-width: 950px) {
  .checkboxStyle:before {
    width: 0.625rem;
    height: 0.625rem;
  }
}
.checkboxStyle:focus {
  outline: 0.0625rem solid #1c1a1d;
}
.checkboxStyle ~ label {
  line-height: 1.5;
  padding: 0.125rem 0;
}

.buttonStyle {
  width: 13.875rem;
  background: #fff;
  color: #1c1920;
  text-align: center;
  line-height: 2.625rem;
  border: 0;
  border-radius: 0;
  appearance: none;
  transition: background 0.2s ease-out;
}
.buttonStyle a {
  line-height: 3rem;
}
.buttonStyle:hover {
  background: rgba(255, 255, 255, 0.6);
}
.buttonStyle:focus {
  outline: 0.0625rem solid #1c1a1d;
}
.buttonStyle.cancel {
  background: rgba(255, 255, 255, 0.15);
}
.buttonStyle.submit {
  background: #1c1a1d;
}
.buttonStyle.goback {
  background: #1c1a1d;
  color: #fff;
}

.kv_swiper {
  position: relative;
}
.kv_swiper .swiper {
  position: relative;
}
.kv_swiper .swiper-slide {
  position: relative;
  box-sizing: border-box;
  background: #fff;
}
@media screen and (min-width: 951px) {
  .kv_swiper .swiper-slide:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .kv_swiper .swiper-slide:focus:after {
    opacity: 1;
  }
}
.kv_swiper .swiper-slide:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.kv_swiper .swiper-slide[data-href^=http] {
  cursor: pointer;
}
.kv_swiper .swiper-pagination {
  width: auto;
  display: flex;
  position: absolute;
  top: auto;
  left: auto;
  bottom: 0;
  right: 7.5rem;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 2.6875rem;
}
@media screen and (max-width: 480px) {
  .kv_swiper .swiper-pagination {
    padding: 0 3.75rem;
    right: 50%;
    transform: translateX(50%);
    bottom: -3rem;
    margin-bottom: 0;
  }
}
.kv_swiper .swiper-pagination-bullet {
  display: block;
  background-color: #fff;
  opacity: 0.6;
}
.kv_swiper .swiper-pagination-bullet::after {
  border-color: #f8df0a;
}
.kv_swiper .swiper-pagination-bullet-active {
  width: 1.875rem;
  opacity: 1;
}
.kv_swiper .swiper-button-prev {
  left: 0;
}
.kv_swiper .swiper-button-next {
  right: 0;
}

.kv_blur_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.kv_blur_bg::after {
  content: "";
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
}

.kv_window {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.kv_controller {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.kv_controller .container {
  max-width: none;
  height: 7.75rem;
}
@media screen and (max-width: 480px) {
  .kv_controller .container {
    height: 5rem;
  }
}

.kv_iframe_yt {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: unset;
  background: #000;
  position: absolute;
  z-index: -1;
}

.scrolldown {
  position: absolute;
  bottom: 1.875rem;
  left: 7.5rem;
  transform: translateX(-50%);
  animation: scrolldown_opacity 1.7s ease-out infinite;
  z-index: 1;
}
@media screen and (max-width: 950px) {
  .scrolldown {
    display: none;
  }
}

.scrolldown_txt {
  font-size: 1.125rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  white-space: nowrap;
  line-height: 1;
  position: absolute;
  bottom: -0.25rem;
  left: 100%;
  margin-left: 0.9375rem;
}

.scrolldown_arrow {
  width: 0.9375rem;
  animation: scrolldown_icon 1.7s ease-out infinite;
}

@keyframes scrolldown_icon {
  0% {
    transform: translateY(-0.625rem);
  }
  55% {
    transform: translateY(0.625rem);
  }
  100% {
    transform: translateY(-0.625rem);
  }
}
@keyframes scrolldown_opacity {
  0% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.kv {
  width: 100%;
  background-image: url("../../img/bg.jpg");
  background-size: 150px auto;
  animation: bg 0.4s infinite step-start both;
  position: absolute;
  top: 0%;
  left: 0%;
}
@media screen and (max-width: 950px) {
  .kv {
    width: 100%;
    top: 0;
    left: 0;
  }
}

.track,
.letter,
.letter_txt,
.circle,
.main_obj,
.obj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.track {
  width: 58.3854166667%;
  z-index: 0;
}
@media screen and (max-width: 480px) {
  .track {
    width: 96%;
  }
}

.main_obj {
  width: 28.75%;
  top: 60.5%;
  left: 53%;
}
@media screen and (max-width: 480px) {
  .main_obj {
    width: 49.6842105263%;
    top: 53.4%;
    left: 53%;
  }
}

.obj_1 {
  width: 15.15625%;
  top: 71%;
  left: 29%;
}
@media screen and (max-width: 480px) {
  .obj_1 {
    width: 27.2631578947%;
    top: 73.2%;
    left: 24.5%;
    z-index: 2;
  }
}

.obj_2 {
  width: 19.84375%;
  top: 52%;
  left: 18%;
}
@media screen and (max-width: 480px) {
  .obj_2 {
    width: 36.4210526316%;
    top: 51.8%;
    left: 18.4%;
  }
}

.obj_3 {
  width: 29.375%;
  top: 24%;
  left: 26.5%;
}
@media screen and (max-width: 480px) {
  .obj_3 {
    width: 35.0526315789%;
    top: 28%;
    left: 25.2%;
  }
}

.obj_4 {
  width: 16.71875%;
  top: 18.2%;
  left: 54%;
}
@media screen and (max-width: 480px) {
  .obj_4 {
    width: 24.8421052632%;
    top: 18.5%;
    left: 60%;
  }
}

.obj_5 {
  width: 23.0729166667%;
  top: 29.7%;
  left: 73%;
}
@media screen and (max-width: 480px) {
  .obj_5 {
    width: 29.8947368421%;
    top: 29.2%;
    left: 77.8%;
    z-index: 2;
  }
}

.obj_6 {
  width: 21.5104166667%;
  top: 47.5%;
  left: 83.5%;
}
@media screen and (max-width: 480px) {
  .obj_6 {
    width: 27.7894736842%;
    top: 57%;
    left: 83.5%;
  }
}

.obj_7 {
  width: 19.1145833333%;
  top: 74.5%;
  left: 73.5%;
}
@media screen and (max-width: 480px) {
  .obj_7 {
    width: 33.8947368421%;
    top: 76%;
    left: 68%;
  }
}

.letter {
  width: 3.6458333333%;
}
@media screen and (max-width: 480px) {
  .letter {
    width: 7.3684210526%;
  }
}

.letter_t {
  top: 15%;
  left: 43%;
}
@media screen and (max-width: 480px) {
  .letter_t {
    top: 29.7%;
    left: 43.5%;
  }
}

.letter_f {
  top: 48.5%;
  left: 31.4%;
}
@media screen and (max-width: 480px) {
  .letter_f {
    top: 42.2%;
    left: 83%;
  }
}

.letter_a {
  top: 58.5%;
  left: 68.3%;
}
@media screen and (max-width: 480px) {
  .letter_a {
    top: 58.8%;
    left: 34%;
  }
}

.letter_m {
  top: 86.4%;
  left: 55%;
}
@media screen and (max-width: 480px) {
  .letter_m {
    top: 64.5%;
    left: 65.5%;
  }
}

.letter_txt {
  width: 1.875%;
}
@media screen and (max-width: 480px) {
  .letter_txt {
    width: 4.5263157895%;
  }
}

.letter_1 {
  top: 26.5%;
  left: 44.2%;
}
@media screen and (max-width: 480px) {
  .letter_1 {
    top: 21.2%;
    left: 31%;
  }
}

.letter_2 {
  top: 35.5%;
  left: 58.7%;
}
@media screen and (max-width: 480px) {
  .letter_2 {
    top: 37.1%;
    left: 61.8%;
  }
}

.letter_3 {
  top: 53.5%;
  left: 39.5%;
}
@media screen and (max-width: 480px) {
  .letter_3 {
    top: 61.1%;
    left: 19.7%;
  }
}

.letter_4 {
  top: 76.2%;
  left: 56%;
}
@media screen and (max-width: 480px) {
  .letter_4 {
    top: 66.3%;
    left: 81%;
  }
}

.circle {
  border-radius: 100px;
}
.circle.large {
  width: 6.1979166667vw;
  height: 6.1979166667vw;
}
@media screen and (max-width: 480px) {
  .circle.large {
    width: 10.2105263158vw;
    height: 10.2105263158vw;
  }
}
.circle.small {
  width: 1.1979166667vw;
  height: 1.1979166667vw;
}
@media screen and (max-width: 480px) {
  .circle.small {
    width: 2.3157894737vw;
    height: 2.3157894737vw;
  }
}
.circle.circle_1 {
  background: #ec6138;
  top: 65%;
  left: 37.6%;
  z-index: 0;
}
@media screen and (max-width: 480px) {
  .circle.circle_1 {
    top: 79.5%;
    left: 31.5%;
  }
}
.circle.circle_2 {
  background: #e8c2c4;
  top: 57%;
  left: 21%;
}
@media screen and (max-width: 480px) {
  .circle.circle_2 {
    top: 56.5%;
    z-index: 0;
  }
}
.circle.circle_3 {
  background: #59503a;
  top: 14.7%;
  left: 29.5%;
}
@media screen and (max-width: 480px) {
  .circle.circle_3 {
    top: 21.6%;
    left: 15.5%;
  }
}
.circle.circle_4 {
  background: #355b87;
  top: 25.2%;
  left: 50.4%;
}
@media screen and (max-width: 480px) {
  .circle.circle_4 {
    top: 21.5%;
    left: 51.5%;
  }
}
.circle.circle_5 {
  background: #d8c506;
  top: 16%;
  left: 71.3%;
}
@media screen and (max-width: 480px) {
  .circle.circle_5 {
    top: 22.5%;
    left: 84.4%;
  }
}
.circle.circle_6 {
  background: #7a5e8e;
  top: 54.3%;
  left: 75.3%;
}
@media screen and (max-width: 480px) {
  .circle.circle_6 {
    top: 56%;
    left: 72.5%;
    z-index: 0;
  }
}
.circle.circle_7 {
  background: #698649;
  top: 73%;
  left: 65.1%;
}
@media screen and (max-width: 480px) {
  .circle.circle_7 {
    top: 73%;
    left: 56%;
    z-index: 0;
  }
}
.circle.circle_8 {
  background: #d8c506;
  top: 91.5%;
  left: 32.7%;
}
@media screen and (max-width: 480px) {
  .circle.circle_8 {
    top: 91.5%;
    left: 14%;
  }
}
.circle.circle_9 {
  width: 1.1458333333vw;
  height: 1.1458333333vw;
  background: #7a5e8e;
  top: 80.5%;
  left: 16.2%;
}
@media screen and (max-width: 480px) {
  .circle.circle_9 {
    width: 2.3157894737vw;
    height: 2.3157894737vw;
    top: 69%;
    left: 11%;
  }
}
.circle.circle_10 {
  background: #698649;
  top: 28.8%;
  left: 13.3%;
}
@media screen and (max-width: 480px) {
  .circle.circle_10 {
    top: 14%;
    left: 40%;
  }
}
.circle.circle_11 {
  background: #59503a;
  top: 27%;
  left: 88.4%;
}
.circle.circle_12 {
  width: 1.3541666667vw;
  height: 1.3541666667vw;
  background: #ec6138;
  top: 37.5%;
  left: 80.6%;
}
@media screen and (max-width: 480px) {
  .circle.circle_12 {
    width: 2.3157894737vw;
    height: 2.3157894737vw;
    top: 36%;
    left: 57%;
  }
}
.circle.circle_13 {
  background: #355b87;
  top: 81%;
  left: 86%;
}
@media screen and (max-width: 480px) {
  .circle.circle_13 {
    top: 81%;
    left: 43.5%;
  }
}
.circle.circle_14 {
  width: 1.1458333333vw;
  height: 1.1458333333vw;
  background: #e8c2c4;
  top: 94%;
  left: 70%;
}
@media screen and (max-width: 480px) {
  .circle.circle_14 {
    width: 2.2105263158vw;
    height: 2.2105263158vw;
    top: 65%;
    left: 57.5%;
  }
}

.hashtags_sec {
  background: #1c1a1d;
  padding: 4.0625rem 0 4.0625rem;
}
.hashtags_sec .container {
  max-width: 100rem;
}

.hashtags_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.125rem;
}

.hashtags_li {
  padding: 0.9375rem 0.5rem;
}
@media screen and (max-width: 480px) {
  .hashtags_li {
    padding: 0.625rem 0.5rem;
  }
}

.hashtags_link {
  font-size: 0.9375rem;
  color: #fff;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0);
  line-height: 2.5rem;
  padding: 0 1.5625rem;
  box-sizing: border-box;
}
@media screen and (min-width: 951px) {
  .hashtags_link:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .hashtags_link:focus:after {
    opacity: 1;
  }
}
.hashtags_link::after {
  border-color: #fff;
}
.hashtags_link:hover {
  color: #fff;
  background: transparent;
  border-color: #ab272a;
}
.hashtags_link.color_0:hover {
  border-color: #fff;
}
.hashtags_link.color_1:hover {
  border-color: #ab272a;
}
.hashtags_link.color_2:hover {
  border-color: #f8df0a;
}
.hashtags_link.color_3:hover {
  border-color: #070fbe;
}
.hashtags_link.color_4:hover {
  border-color: #085596;
}
.hashtags_link.color_5:hover {
  border-color: #026235;
}

.news_sec {
  padding: 8.125rem 0;
}
.news_sec .maintitle_row {
  margin-bottom: 1.25rem;
}
.news_sec .more_row {
  margin-top: 2.5rem;
}
.news_sec .container {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 480px) {
  .news_sec .container {
    display: block;
  }
}

.news_container_left {
  flex: 1;
}

.news_container_right {
  flex: 0 0 18.75rem;
}

.news_swiper .swiper-wrapper {
  display: block;
}
.news_swiper .swiper-slide {
  padding: 0.625rem 0;
  border-bottom: 1px solid rgba(28, 26, 29, 0.1);
}
.news_swiper .swiper-slide:last-child {
  border-bottom: 0;
}

.news_row {
  display: flex;
}

.news_left {
  width: 2.3125rem;
  flex: 0 0 2.3125rem;
  position: relative;
}

.news_right {
  min-width: 0;
  flex: 1;
  position: relative;
  padding-top: 0.3125rem;
}

.news_date {
  font-size: 1.5625rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  white-space: nowrap;
  margin-bottom: 0.125rem;
}

.news_title {
  font-size: 2.125rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.3125rem;
}
@media screen and (max-width: 480px) {
  .news_title {
    font-size: 1.875rem;
    white-space: unset;
    margin-top: 0.1875rem;
  }
}

.news_txt {
  font-size: 1.0625rem;
  line-height: 2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news_tag {
  color: #ab272a;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  font-size: 15px;
  display: inline;
  margin-left: 10px;
  animation: column_tag 1.5s linear infinite;
}

.column_left {
  width: 4.875rem;
  flex: 0 0 4.875rem;
  padding: 0 1.3125rem 0 1.375rem;
  box-sizing: border-box;
}

.column_label {
  padding-bottom: 0.625rem;
  position: relative;
}
.column_label::after {
  content: "";
  width: 100%;
  height: 0.625rem;
  background-image: url("../../img/label.png");
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: background-image 0.2s ease-out;
}

.column_item:hover .column_label.color_1::after, .column_item:focus .column_label.color_1::after {
  background-image: url("../../img/label_1.png");
}
.column_item:hover .column_label.color_1 .column_label_txt, .column_item:focus .column_label.color_1 .column_label_txt {
  background: #ab272a;
}
.column_item:hover .column_label.color_2::after, .column_item:focus .column_label.color_2::after {
  background-image: url("../../img/label_2.png");
}
.column_item:hover .column_label.color_2 .column_label_txt, .column_item:focus .column_label.color_2 .column_label_txt {
  background: #f8df0a;
}
.column_item:hover .column_label.color_3::after, .column_item:focus .column_label.color_3::after {
  background-image: url("../../img/label_3.png");
}
.column_item:hover .column_label.color_3 .column_label_txt, .column_item:focus .column_label.color_3 .column_label_txt {
  background: #070fbe;
}
.column_item:hover .column_label.color_4::after, .column_item:focus .column_label.color_4::after {
  background-image: url("../../img/label_4.png");
}
.column_item:hover .column_label.color_4 .column_label_txt, .column_item:focus .column_label.color_4 .column_label_txt {
  background: #085596;
}
.column_item:hover .column_label.color_5::after, .column_item:focus .column_label.color_5::after {
  background-image: url("../../img/label_5.png");
}
.column_item:hover .column_label.color_5 .column_label_txt, .column_item:focus .column_label.color_5 .column_label_txt {
  background: #026235;
}

.column_label_txt {
  font-size: 1.25rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  color: #026235;
  margin-bottom: 0.3125rem;
  transition: color 0.1s ease-out;
}

.column_link:hover .column_label_txt, .column_link:focus .column_label_txt {
  color: #070fbe;
}
.column_link:hover .column_pic, .column_link:focus .column_pic {
  opacity: 0.7;
}

.column_title {
  max-height: 25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.5rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  line-height: 1.2;
  writing-mode: vertical-lr;
  letter-spacing: 0.0625rem;
  position: relative;
  margin: 1.875rem 0;
}
@media screen and (max-width: 480px) {
  .column_title {
    line-height: 1.4;
    left: -0.2rem;
  }
}

.column_label ~ .column_title {
  margin: 1.125rem 0;
}

.column_pic {
  position: relative;
  transition: opacity 0.1s ease-out;
}
.column_pic .img.contain {
  object-fit: contain;
  backdrop-filter: blur(5px);
}

.column_caption {
  padding: 1.75rem 0 1.25rem;
}

.column_caption_title {
  font-size: 1.75rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  font-weight: bold;
  line-height: 1.3;
}

.column_caption_txt {
  font-size: 1.25rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  margin-top: 0.9375rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
@media screen and (max-width: 480px) {
  .column_caption_txt {
    font-size: 1.375rem;
  }
}

.column_caption_uid {
  font-size: 1.25rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  margin-top: 0.3125rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 480px) {
  .column_caption_uid {
    font-size: 1.375rem;
  }
}
.column_caption_uid span {
  padding-right: 0.625rem;
  margin-right: 0.375rem;
  position: relative;
}
.column_caption_uid span:after {
  content: "";
  width: 1px;
  height: 1.125rem;
  background: rgba(28, 26, 29, 0.7);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.column_tag {
  color: #fff;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  font-weight: bold;
  background: #ab272a;
  padding: 4px 15px 3px;
  position: absolute;
  top: 0;
  right: 0;
  animation: column_tag 1.5s linear infinite;
}

@keyframes column_tag {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  80% {
    opacity: 1;
  }
}
.column_sec {
  padding: 8.125rem 0;
}
@media screen and (max-width: 950px) {
  .column_sec {
    padding-top: 0;
  }
}

.column_swiper {
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  margin-top: 3.4375rem;
  margin-bottom: 2.5rem;
  padding-bottom: 3.125rem;
  position: relative;
}
@media screen and (max-width: 950px) {
  .column_swiper {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
}
@media screen and (max-width: 480px) {
  .column_swiper {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}
.column_swiper .swiper {
  overflow: visible;
}
.column_swiper .swiper-slide {
  padding: 0 1.875rem;
  box-sizing: border-box;
}
@media screen and (max-width: 950px) {
  .column_swiper .swiper-slide {
    padding: 0 1.25rem;
  }
}
@media screen and (max-width: 480px) {
  .column_swiper .swiper-slide {
    padding: 0 0.625rem;
  }
}
.column_swiper .column_title {
  font-size: 1.875rem;
  left: -0.3125rem;
}
.column_swiper .column_li_title {
  font-size: 1.25rem;
  font-weight: bold;
}
.column_swiper .column_li_txt {
  font-size: 1.125rem;
  margin-top: 0.625rem;
}

.arti_list {
  margin-bottom: 0.9375rem;
}

.arti_li {
  padding: 0.625rem 0;
}

@media screen and (min-width: 951px) {
  .arti_link:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .arti_link:focus:after {
    opacity: 1;
  }
}
.arti_link::before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(28, 26, 29, 0.1);
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  transition: width 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.arti_link:focus::before, .arti_link:hover::before {
  width: 100vw;
}
.arti_link:focus:hover .arti_label.color_1::after, .arti_link:focus:focus .arti_label.color_1::after, .arti_link:hover:hover .arti_label.color_1::after, .arti_link:hover:focus .arti_label.color_1::after {
  background-image: url("../../img/label_1.png");
}
.arti_link:focus:hover .arti_label.color_1 .arti_label_txt, .arti_link:focus:focus .arti_label.color_1 .arti_label_txt, .arti_link:hover:hover .arti_label.color_1 .arti_label_txt, .arti_link:hover:focus .arti_label.color_1 .arti_label_txt {
  background: #ab272a;
}
.arti_link:focus:hover .arti_label.color_2::after, .arti_link:focus:focus .arti_label.color_2::after, .arti_link:hover:hover .arti_label.color_2::after, .arti_link:hover:focus .arti_label.color_2::after {
  background-image: url("../../img/label_2.png");
}
.arti_link:focus:hover .arti_label.color_2 .arti_label_txt, .arti_link:focus:focus .arti_label.color_2 .arti_label_txt, .arti_link:hover:hover .arti_label.color_2 .arti_label_txt, .arti_link:hover:focus .arti_label.color_2 .arti_label_txt {
  background: #f8df0a;
}
.arti_link:focus:hover .arti_label.color_3::after, .arti_link:focus:focus .arti_label.color_3::after, .arti_link:hover:hover .arti_label.color_3::after, .arti_link:hover:focus .arti_label.color_3::after {
  background-image: url("../../img/label_3.png");
}
.arti_link:focus:hover .arti_label.color_3 .arti_label_txt, .arti_link:focus:focus .arti_label.color_3 .arti_label_txt, .arti_link:hover:hover .arti_label.color_3 .arti_label_txt, .arti_link:hover:focus .arti_label.color_3 .arti_label_txt {
  background: #070fbe;
}
.arti_link:focus:hover .arti_label.color_4::after, .arti_link:focus:focus .arti_label.color_4::after, .arti_link:hover:hover .arti_label.color_4::after, .arti_link:hover:focus .arti_label.color_4::after {
  background-image: url("../../img/label_4.png");
}
.arti_link:focus:hover .arti_label.color_4 .arti_label_txt, .arti_link:focus:focus .arti_label.color_4 .arti_label_txt, .arti_link:hover:hover .arti_label.color_4 .arti_label_txt, .arti_link:hover:focus .arti_label.color_4 .arti_label_txt {
  background: #085596;
}
.arti_link:focus:hover .arti_label.color_5::after, .arti_link:focus:focus .arti_label.color_5::after, .arti_link:hover:hover .arti_label.color_5::after, .arti_link:hover:focus .arti_label.color_5::after {
  background-image: url("../../img/label_5.png");
}
.arti_link:focus:hover .arti_label.color_5 .arti_label_txt, .arti_link:focus:focus .arti_label.color_5 .arti_label_txt, .arti_link:hover:hover .arti_label.color_5 .arti_label_txt, .arti_link:hover:focus .arti_label.color_5 .arti_label_txt {
  background: #026235;
}

.arti_row {
  display: flex;
}

.arti_left {
  width: 3.5625rem;
  flex: 0 0 3.5625rem;
  padding-left: 1.5625rem;
  padding-bottom: 0.625rem;
  box-sizing: border-box;
}

.arti_middle {
  min-width: 0;
  flex: 1;
  padding: 1.5625rem 2.5rem 1.25rem;
  display: flex;
}
@media screen and (max-width: 480px) {
  .arti_middle {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.arti_right {
  width: 10.625rem;
  flex: 0 0 10.625rem;
  padding: 1.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.arti_right::before {
  content: "";
  width: 1px;
  height: calc(100% - 2.5rem);
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.arti_author {
  font-size: 1.375rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  text-align: center;
}

.arti_author_en {
  font-size: 1.25rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  text-align: center;
}

.arti_label {
  padding-bottom: 0.625rem;
  position: relative;
}
.arti_label::after {
  content: "";
  width: 100%;
  height: 0.625rem;
  background-image: url("../../img/label.png");
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: background-image 0.2s ease-out;
}

.arti_label_txt {
  width: 100%;
  min-height: 7.25rem;
  text-align: center;
  font-size: 1.0625rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  color: #fff;
  writing-mode: vertical-lr;
  letter-spacing: 0.0625rem;
  line-height: 1;
  background: #1c1a1d;
  padding: 1.875rem 0 1.6875rem 0.5rem;
  box-sizing: border-box;
  transition: background 0.2s ease-out;
}

.arti_subtitle {
  font-size: 1.25rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  margin-bottom: 0.1875rem;
}

.arti_title {
  font-size: 1.8125rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
}

.arti_txt {
  line-height: 1.6666666667;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.1875rem;
}

.arti_uid {
  line-height: 1.6666666667;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  margin-top: 0.1875rem;
}

.arti_tag {
  color: #fff;
  font-size: 0.8125rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  background-color: #ab272a;
  padding: 2px 10px 1px;
  border-radius: 10px;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.arti_sec {
  padding-top: 8.125rem;
  padding-bottom: 6.25rem;
}
.arti_sec .container {
  max-width: 65.625rem;
}
.arti_sec .maintitle_row {
  margin-bottom: 3.4375rem;
}
.arti_sec .arti_list {
  margin-bottom: 2.5rem;
}

.search_sec {
  padding: 12.5rem 0 0;
}
.search_sec .container {
  max-width: 51.5625rem;
}
.search_sec .maintitle_en {
  color: #e2e2e2;
}

.search_container {
  position: relative;
}

.search_path {
  width: 171.8787878788%;
  height: 44.2424242424%;
  position: absolute;
  top: 30.5%;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 480px) {
  .search_path {
    width: 200%;
    top: 37.5%;
  }
}

.search_path_svg {
  width: 100%;
  height: 100%;
}

.search_tag {
  position: absolute;
  top: 5%;
  left: 50%;
}

.search_tag_link:hover .search_tag_dot, .search_tag_link:focus .search_tag_dot {
  background: #fff;
}
.search_tag_link:hover .search_tag_dot::before, .search_tag_link:focus .search_tag_dot::before {
  transform: translate(-50%, -50%) scale(1);
}
.search_tag_link:hover .search_tag_dot.color_1::before, .search_tag_link:focus .search_tag_dot.color_1::before {
  background: #ab272a;
}
.search_tag_link:hover .search_tag_dot.color_2::before, .search_tag_link:focus .search_tag_dot.color_2::before {
  background: #f8df0a;
}
.search_tag_link:hover .search_tag_dot.color_3::before, .search_tag_link:focus .search_tag_dot.color_3::before {
  background: #070fbe;
}
.search_tag_link:hover .search_tag_dot.color_4::before, .search_tag_link:focus .search_tag_dot.color_4::before {
  background: #085596;
}
.search_tag_link:hover .search_tag_dot.color_5::before, .search_tag_link:focus .search_tag_dot.color_5::before {
  background: #026235;
}
.search_tag_link:hover .dot_cross, .search_tag_link:focus .dot_cross {
  opacity: 1;
  transform: rotate(360deg);
}

.search_tag_dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #1c1a1d;
  position: relative;
}
.search_tag_dot::before {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background: #ab272a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  z-index: -1;
}

.dot_cross {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: transform 0.8s ease-out;
}
.dot_cross::before, .dot_cross::after {
  content: "";
  width: 1.5rem;
  height: 0.0625rem;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dot_cross::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.search_tag_txt {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center 100%;
  animation: search_tag_txt 20s linear infinite;
}

@keyframes search_tag_txt {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
.search_tag_t {
  font-size: 1.0625rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  white-space: nowrap;
  padding: 0.9375rem 0;
  position: relative;
  transform-origin: center 100%;
  animation: search_tag_t 20s linear infinite;
}

@keyframes search_tag_t {
  100% {
    transform: rotate(-360deg);
  }
}
.search_bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 480px) {
  .search_bg {
    width: 132%;
  }
}

.search_center {
  width: 100%;
}
.search_center .search_bg {
  position: relative;
}

.search_inner {
  width: 100%;
  padding-top: 8.75rem;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 480px) {
  .search_inner {
    padding-top: 20vw;
  }
}
.search_inner .container {
  max-width: 38.375rem;
  width: calc(100% - 6.5625rem);
}

.search_form {
  margin-top: 1.875rem;
}

.search_submit {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 6.25rem;
  background: #ab272a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: "GenWanMin-SB", "微軟正黑體", "蘋果儷黑體", Arial, sans-serif, Verdana;
  color: #fff;
  margin: 0 auto;
  margin-top: 3.75rem;
  position: relative;
  cursor: pointer;
}
.search_submit::before, .search_submit::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 6.25rem;
  background: #ab272a;
  position: absolute;
  top: 0;
  left: 0;
}
.search_submit:before {
  animation: ring_1 2s cubic-bezier(0.25, 1, 0.5, 1) infinite;
}
.search_submit:after {
  animation: ring_2 2s cubic-bezier(0.25, 1, 0.5, 1) infinite;
}

@keyframes ring_1 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
@keyframes ring_2 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
.search_submit_txt {
  position: relative;
  z-index: 1;
}

.gallery_sec {
  position: relative;
  padding-top: 12.5rem;
  padding-bottom: 8.125rem;
  z-index: 1;
}
.gallery_sec.dark {
  padding-top: 8.125rem;
  padding-bottom: 5rem;
}
.gallery_sec.dark .gallery_prev::before, .gallery_sec.dark .gallery_prev::after,
.gallery_sec.dark .gallery_next::before,
.gallery_sec.dark .gallery_next::after {
  background: #4d4c4c;
}
.gallery_sec.dark .gallery_prev:hover::before, .gallery_sec.dark .gallery_prev:hover::after, .gallery_sec.dark .gallery_prev:focus::before, .gallery_sec.dark .gallery_prev:focus::after,
.gallery_sec.dark .gallery_next:hover::before,
.gallery_sec.dark .gallery_next:hover::after,
.gallery_sec.dark .gallery_next:focus::before,
.gallery_sec.dark .gallery_next:focus::after {
  background: #fff;
}
.gallery_sec.dark .more_txt {
  color: #fff;
}
.gallery_sec.dark .fa-angle-right {
  color: #fff;
  transition-delay: 0.15s;
}
.gallery_sec.dark .more_link::after {
  border-color: #fff;
}
.gallery_sec .more_row {
  margin-top: 5.25rem;
}
@media screen and (max-width: 950px) {
  .gallery_sec .more_row {
    margin-top: 0;
  }
}
@media screen and (max-width: 480px) {
  .gallery_sec .more_row {
    margin-top: 2rem;
  }
}

.gallery_container {
  height: 34rem;
  position: relative;
  margin-top: 3.125rem;
}
@media screen and (max-width: 950px) {
  .gallery_container {
    height: 60vw;
    overflow: hidden;
  }
}
@media screen and (max-width: 480px) {
  .gallery_container {
    height: 81vw;
  }
}
.gallery_container .container {
  max-width: 105rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.gallery_spinner {
  width: 230vw;
  height: 230vw;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -115vw;
}
@media screen and (max-width: 950px) {
  .gallery_spinner {
    width: 400vw;
    height: 380vw;
    margin-left: -200vw;
  }
}
@media screen and (max-width: 480px) {
  .gallery_spinner {
    width: 600vw;
    height: 570vw;
    margin-left: -300vw;
  }
}

.gallery_item {
  width: 9.0126811594%;
  height: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -4.5063405797%;
  transform-origin: center bottom;
}
.gallery_item:nth-child(1) {
  transform: rotate(-30deg);
}
.gallery_item:nth-child(2) {
  transform: rotate(-15deg);
}
.gallery_item:nth-child(3) {
  transform: rotate(0deg);
}
.gallery_item:nth-child(4) {
  transform: rotate(15deg);
}
.gallery_item:nth-child(5) {
  transform: rotate(30deg);
}

.gallery_prev,
.gallery_next {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 7.5rem;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.gallery_prev::before, .gallery_prev:after,
.gallery_next::before,
.gallery_next:after {
  content: "";
  width: 40%;
  height: 0.1875rem;
  background: #000;
  background: #bbb;
  position: absolute;
  left: 30%;
  transition: background 0.2s ease-out;
}
.gallery_prev:hover::before, .gallery_prev:hover:after, .gallery_prev:focus::before, .gallery_prev:focus:after,
.gallery_next:hover::before,
.gallery_next:hover:after,
.gallery_next:focus::before,
.gallery_next:focus:after {
  background: #4d4d4d;
}

.gallery_prev::before {
  top: 50%;
  transform-origin: left top;
  transform: rotate(-45deg);
}
.gallery_prev::after {
  bottom: 50%;
  transform-origin: left bottom;
  transform: rotate(45deg);
}

.gallery_next::before {
  top: 50%;
  transform-origin: right top;
  transform: rotate(45deg);
}
.gallery_next::after {
  bottom: 50%;
  transform-origin: right bottom;
  transform: rotate(-45deg);
}

.gallery_prev {
  left: 0;
}

.gallery_next {
  right: 0;
}

.gallery_btn_link {
  flex: 1;
  height: 100%;
}
@media screen and (min-width: 951px) {
  .gallery_btn_link:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .gallery_btn_link:focus:after {
    opacity: 1;
  }
}
.gallery_btn_link::after {
  border-color: #fff;
}

.gallery_bg {
  width: 100vw;
  height: 100vw;
  border-radius: 100vw;
  background: #1c1a1d;
  position: fixed;
  top: 100%;
  left: 50%;
  transform: scale(0);
  margin-left: -50vw;
  margin-top: -20vw;
  z-index: 1;
}
@media screen and (max-width: 950px) {
  .gallery_bg {
    width: 100vh;
    height: 100vh;
    margin-left: -50vh;
    margin-top: -30vh;
  }
}

@media screen and (min-width: 951px) {
  .gallery_link:after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px dotted #ab272a;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
  .gallery_link:focus:after {
    opacity: 1;
  }
}
.gallery_link::after {
  border-color: #fff;
}