.news_sec {
    padding: 130 / $setFs + rem 0;

    .maintitle_row {
        margin-bottom: 20 / $setFs + rem;
    }

    .more_row {
        margin-top: 40 / $setFs + rem;
    }

    .container {
        // max-width: none;
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 480px) {
            display: block;
        }
    }
}

.news_container_left {
    flex: 1;
}

.news_container_right {
    flex: 0 0 300 / $setFs + rem;
}

.news_swiper {

    .swiper-wrapper {
        display: block;
    }

    .swiper-slide {
        padding: 10 / $setFs + rem 0;
        border-bottom: 1px solid rgba($txtColor, .1);

        &:last-child {
            border-bottom: 0;
        }
    }
}

// .news_swiper {
//     .swiper {
//         overflow: visible;
//     }

//     .swiper-slide {
//         &:nth-child(2n+1) {
//             padding-top: 205 / $setFs + rem;
//         }

//         &:nth-last-child(-n+2) {
//             .news_item {
//                 &::after {
//                     width: calc(100% - #{5 / $setFs + rem});
//                 }
//             }
//         }
//     }
// }

// .news_item {
//     position: relative;
//     padding: 25 / $setFs + rem 0;

//     &::before {
//         content: '';
//         width: 12 / $setFs + rem;
//         height: 12 / $setFs + rem;
//         border-radius: 12 / $setFs + rem;
//         background: $color;
//         position: absolute;
//         top: 0;
//         left: 5 / $setFs + rem;
//         transform: translateY(-50%);
//     }

//     &::after {
//         content: '';
//         width: 200%;
//         height: 1 / $setFs + rem;
//         background: rgba(0, 0, 0, 0.2);
//         position: absolute;
//         top: 0;
//         left: 5 / $setFs + rem;
//     }
// }

.news_row {
    display: flex;
}

.news_left {
    width: 37 / $setFs + rem;
    flex: 0 0 37 / $setFs + rem;
    position: relative;
    // order: 2;
}

.news_right {
    min-width: 0;
    flex: 1;
    position: relative;
    padding-top: 5 / $setFs + rem;
    // order: 1;
    // text-align: left;
}

.news_date {
    // font-size: 19 / $setFs + rem;
    font-size: 25 / $setFs + rem;
    font-family: $font1;
    white-space: nowrap;
    margin-bottom: 2 / $setFs + rem;
    // line-height: 1;
    // position: absolute;
    // left: 0;
    // bottom: 100%;
    // transform: rotate(90deg);
    // transform-origin: left bottom;
    // padding-left: 10 / $setFs + rem;
}

.news_title {
    // font-size: 20 / $setFs + rem;
    font-size: 34 / $setFs + rem;
    font-family: $font1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5 / $setFs + rem;

    @media screen and (max-width: 480px) {
        font-size: 30 / $setFs + rem;
        white-space: unset;
        margin-top: 3 / $setFs + rem;
    }
}

.news_txt {
    font-size: 17 / $setFs + rem;
    line-height: (36/18);
    @include word_limit(3);
}

.news_tag {
    color: $color4;
    font-family: $font1;
    font-size: 15px;
    display: inline;
    margin-left: 10px;
    animation: column_tag 1.5s linear infinite;
}