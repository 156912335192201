// .column_item {
// display: flex;
// background: rgba($color, 0.1);
// }

.column_left {
    width: 78 / $setFs + rem;
    flex: 0 0 78 / $setFs + rem;
    padding: 0 21 / $setFs + rem 0 22 / $setFs + rem;
    box-sizing: border-box;
}

// .column_right {
// flex: 1;
// padding: 30 / $setFs + rem 0;
// box-sizing: border-box;
// }

// .column_inner {
// padding: 0 30 / $setFs + rem;
// box-sizing: border-box;
// border-left: 1 / $setFs + rem solid rgba(0, 0, 0, 0.2);
// }

.column_label {
    padding-bottom: 10 / $setFs + rem;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 10 / $setFs + rem;
        background-image: url('../../img/label.png');
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: background-image .2s ease-out;
    }
}

.column_item {

    &:hover,
    &:focus {

        .column_label {

            &.color_1 {
                &::after {
                    background-image: url('../../img/label_1.png');
                }

                .column_label_txt {
                    background: #ab272a;
                }
            }

            &.color_2 {
                &::after {
                    background-image: url('../../img/label_2.png');
                }

                .column_label_txt {
                    background: #f8df0a;
                }
            }

            &.color_3 {
                &::after {
                    background-image: url('../../img/label_3.png');
                }

                .column_label_txt {
                    background: #070fbe;
                }
            }

            &.color_4 {
                &::after {
                    background-image: url('../../img/label_4.png');
                }

                .column_label_txt {
                    background: #085596;
                }
            }

            &.color_5 {
                &::after {
                    background-image: url('../../img/label_5.png');
                }

                .column_label_txt {
                    background: #026235;
                }
            }
        }
    }
}

.column_label_txt {
    // width: 100%;
    // font-size: 17 / $setFs + rem;
    // font-family: $font1;
    // color: #fff;
    // writing-mode: vertical-lr;
    // letter-spacing: 1 / $setFs + rem;
    // line-height: 1;
    // background: $color;
    // padding: 30 / $setFs + rem 0 27 / $setFs + rem 8 / $setFs + rem;
    // box-sizing: border-box;
    // transition: background .2s ease-out;
    font-size: (15 + 5) / $setFs + rem;
    font-family: $font1;
    color: #026235;
    margin-bottom: 5 / $setFs + rem;
    transition: color .1s ease-out;
}

.column_link {

    &:hover,
    &:focus {
        .column_label_txt {
            color: #070fbe;
        }

        .column_pic {
            opacity: .7;
        }
    }
}

.column_title {
    max-height: 400 / $setFs + rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 24 / $setFs + rem;
    font-family: $font1;
    line-height: 1.2;
    writing-mode: vertical-lr;
    letter-spacing: 1 / $setFs + rem;
    position: relative;
    margin: 30 / $setFs + rem 0;

    @media screen and (max-width: 480px) {
        line-height: 1.4;
        left: -0.2rem;
    }
}

.column_label {
    &~.column_title {
        margin: 18 / $setFs + rem 0;
    }
}

.column_pic {
    position: relative;
    transition: opacity .1s ease-out;

    .img {
        // filter: brightness(.5);

        &.contain {
            object-fit: contain;
            // filter: brightness(1); 
            backdrop-filter: blur(5px);
        }
    }
}

.column_caption {
    padding: 28 / $setFs + rem 0 20 / $setFs + rem;
}

// .column_li {
//     padding: 14 / $setFs + rem 0 14 / $setFs + rem 15 / $setFs + rem;
//     position: relative;

//     &::before {
//         content: '';
//         width: 5 / $setFs + rem;
//         height: 5 / $setFs + rem;
//         border-radius: 5 / $setFs + rem;
//         background: $color;
//         position: absolute;
//         top: 23 / $setFs + rem;
//         left: 0;
//     }
// }

.column_caption_title {
    font-size: (18 + 10) / $setFs + rem;
    font-family: $font1;
    font-weight: bold;
    line-height: 1.3;
}

.column_caption_txt {
    font-size: (15 + 5) / $setFs + rem;
    font-family: $font1;
    margin-top: 15 / $setFs + rem;
    @include word_limit(1);
    font-weight: bold;

    @media screen and (max-width: 480px) {
        font-size: (15 + 7) / $setFs + rem;
    }
}

.column_caption_uid {
    font-size: (15 + 5) / $setFs + rem;
    font-family: $font1;
    margin-top: 5 / $setFs + rem;
    @include word_limit(1);

    @media screen and (max-width: 480px) {
        font-size: (15 + 7) / $setFs + rem;
    }

    span {
        padding-right: 10 / $setFs + rem;
        margin-right: 6 / $setFs + rem;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 18 / $setFs + rem;
            background: rgba($txtColor, .7);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.column_tag {
    color: #fff;
    font-family: $font1;
    font-weight: bold;
    background: $color4;
    padding: 4px 15px 3px;
    position: absolute;
    top: 0;
    right: 0;
    animation: column_tag 1.5s linear infinite;
}

@keyframes column_tag {
    20% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    80% {
        opacity: 1;
    }
}