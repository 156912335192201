@import "../../share/components/list_col_item";

.column_sec {
    padding: 130 / $setFs + rem 0;

    @media screen and (max-width: 950px) {
        padding-top: 0;
    }
}

.column_swiper {
    margin-left: -30 / $setFs + rem;
    margin-right: -30 / $setFs + rem;
    margin-top: 55 / $setFs + rem;
    margin-bottom: 40 / $setFs + rem;
    padding-bottom: 50 / $setFs + rem;
    position: relative;

    @media screen and (max-width: 950px) {
        margin-left: -20 / $setFs + rem;
        margin-right: -20 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        margin-left: -10 / $setFs + rem;
        margin-right: -10 / $setFs + rem;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        padding: 0 30 / $setFs + rem;
        box-sizing: border-box;

        @media screen and (max-width: 950px) {
            padding: 0 20 / $setFs + rem;
        }

        @media screen and (max-width: 480px) {
            padding: 0 10 / $setFs + rem;
        }
    }

    .column_title {
        font-size: 30 / $setFs + rem;
        left: -5 / $setFs + rem;
    }

    .column_li_title {
        font-size: 20 / $setFs + rem;
        font-weight: bold;
    }

    .column_li_txt {
        font-size: 18 / $setFs + rem;
        margin-top: 10 / $setFs + rem;
    }
}