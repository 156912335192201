@import "../../share/components/list_row_item";

.arti_sec {
    padding-top: 130 / $setFs + rem;
    padding-bottom: 100 / $setFs + rem;

    .container {
        max-width: 1050 / $setFs + rem;
    }

    .maintitle_row {
        margin-bottom: 55 / $setFs + rem;
    }

    .arti_list {
        margin-bottom: 40 / $setFs + rem;
    }
}

// .arti_title {
// font-size: 26 / $setFs + rem;
// }

// .arti_subtitle {
// font-size: 20 / $setFs + rem;
// margin-top: -5 / $setFs + rem;
// }