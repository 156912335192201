.search_sec {
    padding: 200 / $setFs + rem 0 0;

    .container {
        max-width: 825 / $setFs + rem;
    }

    .maintitle_en {
        color: #e2e2e2;
    }
}

.search_container {
    position: relative;
}

.search_path {
    width: 1418/825*100%;
    height: 365/825*100%;
    position: absolute;
    top: 30.5%;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 480px) {
        width: 200%;
        top: 37.5%;
    }
}

.search_path_svg {
    width: 100%;
    height: 100%;
}

.search_tag {
    position: absolute;
    top: 5%;
    left: 50%;
}

.search_tag_link {

    &:hover,
    &:focus {
        .search_tag_dot {
            background: #fff;

            &::before {
                transform: translate(-50%, -50%) scale(1);
            }

            &.color_1 {
                &::before {
                    background: #ab272a;
                }
            }

            &.color_2 {
                &::before {
                    background: #f8df0a;
                }
            }

            &.color_3 {
                &::before {
                    background: #070fbe;
                }
            }

            &.color_4 {
                &::before {
                    background: #085596;
                }
            }

            &.color_5 {
                &::before {
                    background: #026235;
                }
            }
        }

        .dot_cross {
            opacity: 1;
            transform: rotate(360deg);
        }
    }

}

.search_tag_dot {
    width: 12 / $setFs + rem;
    height: 12 / $setFs + rem;
    border-radius: 12 / $setFs + rem;
    background: $color;
    position: relative;

    &::before {
        content: '';
        width: 40 / $setFs + rem;
        height: 40 / $setFs + rem;
        border-radius: 40 / $setFs + rem;
        background: #ab272a;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: all .4s cubic-bezier(0.34, 1.56, 0.64, 1);
        z-index: -1;
    }
}

.dot_cross {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: transform .8s ease-out;

    &::before,
    &::after {
        content: '';
        width: 24 / $setFs + rem;
        height: 1 / $setFs + rem;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.search_tag_txt {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center 100%;
    animation: search_tag_txt 20s linear infinite;
}

@keyframes search_tag_txt {
    100% {
        transform: translateX(-50%) rotate(360deg);
    }
}

.search_tag_t {
    font-size: 17 / $setFs + rem;
    font-family: $font1;
    white-space: nowrap;
    padding: 15 / $setFs + rem 0;
    position: relative;
    transform-origin: center 100%;
    animation: search_tag_t 20s linear infinite;
}

@keyframes search_tag_t {
    100% {
        transform: rotate(-360deg);
    }
}

.search_bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 480px) {
        width: 132%;
    }
}

.search_center {
    width: 100%;

    .search_bg {
        position: relative;
    }
}

.search_inner {
    width: 100%;
    padding-top: 140 / $setFs + rem;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 480px) {
        padding-top: 20vw;
    }

    .container {
        max-width: 614 / $setFs + rem;
        $x: 105 / $setFs + rem;
        width: calc(100% - #{$x});
    }
}

.search_form {
    margin-top: 30 / $setFs + rem;
}

.search_submit {
    width: 100 / $setFs + rem;
    height: 100 / $setFs + rem;
    border-radius: 100 / $setFs + rem;
    background: #ab272a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24 / $setFs + rem;
    font-family: $font1;
    color: #fff;
    margin: 0 auto;
    margin-top: 60 / $setFs + rem;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100 / $setFs + rem;
        background: #ab272a;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:before {
        animation: ring_1 2s cubic-bezier(0.25, 1, 0.5, 1) infinite;
    }

    &:after {
        animation: ring_2 2s cubic-bezier(0.25, 1, 0.5, 1) infinite;
    }
}

@keyframes ring_1 {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

@keyframes ring_2 {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(2);
    }
}

.search_submit_txt {
    position: relative;
    z-index: 1;
}