.swiper-slide {
    a {
        @include acc;

        &::after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: 108 / $setFs + rem;
    height: 108 / $setFs + rem;
    border-radius: 100 / $setFs + rem;
    transition: opacity .2s ease-out;
    margin-top: -54 / $setFs + rem;
    opacity: .6;
    @include acc;

    @media screen and (max-width: 950px) {
        opacity: 1;
    }

    @media screen and (max-width: 480px) {
        width: 90 / $setFs + rem;
        height: 90 / $setFs + rem;
        margin-top: -45 / $setFs + rem;
    }

    &:after {
        font-size: 0;
    }

    &:hover {
        opacity: 1;
    }

    &.swiper-button-disabled {
        opacity: .1;
    }
}

.swiper-button-prev {
    background-image: url("../../img/swiper_btn_prev.png");
    background-size: contain;
    background-position: center center;
    margin-left: -24 / $setFs + rem;
    left: 30 / $setFs + rem;

    @media screen and (max-width: 950px) {
        left: 20 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        left: 10 / $setFs + rem;
    }
}

.swiper-button-next {
    background-image: url("../../img/swiper_btn_next.png");
    background-size: contain;
    background-position: center center;
    margin-right: -24 / $setFs + rem;
    right: 30 / $setFs + rem;

    @media screen and (max-width: 950px) {
        right: 20 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        right: 10 / $setFs + rem;
    }
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: center;
    bottom: 0;
}

.swiper-pagination-bullet {
    width: 8 / $setFs + rem;
    height: 8 / $setFs + rem;
    border-radius: 8 / $setFs + rem;
    border: 1px solid #222;
    background-color: transparent;
    box-sizing: border-box;
    margin: 0 10 / $setFs + rem !important;
    transition: all .3s ease-out;
    opacity: 1;
    position: relative;
    @include acc;

    @media screen and (max-width: 480px) {
        width: 10 / $setFs + rem;
        height: 10 / $setFs + rem;
    }
}

.swiper-pagination-bullet-active {
    background: #222;
    opacity: 1;
}