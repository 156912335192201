.kv_swiper {
    position: relative;

    .swiper {
        position: relative;
    }

    .swiper-slide {
        position: relative;
        @include acc;
        box-sizing: border-box;
        // padding: 100 / $setFs + rem 0;
        background: #fff;

        &:after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &[data-href^=http] {
            cursor: pointer;
        }
    }

    .swiper-pagination {
        width: auto;
        display: flex;
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 120 / $setFs + rem;
        z-index: 1;
        margin-top: 0;
        margin-bottom: 43 / $setFs + rem;

        @media screen and (max-width: 480px) {
            padding: 0 60 / $setFs + rem;
            right: 50%;
            transform: translateX(50%);
            bottom: -3rem;
            margin-bottom: 0;
        }
    }

    .swiper-pagination-bullet {
        display: block;
        background-color: #fff;
        opacity: .6;

        &::after {
            border-color: $color5;
        }
    }

    .swiper-pagination-bullet-active {
        width: 30 / $setFs + rem;
        opacity: 1;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

.kv_blur_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
        background: rgba(#fff, .8);
        position: absolute;
        top: 0;
        left: 0;
    }
}

.kv_window {
    // width: 1480 / $setFs + rem;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    // @media screen and (max-width: 480px) {
    //     width: 96%;
    // }
}

.kv_controller {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    .container {
        max-width: none;
        height: 124 / $setFs + rem;

        @media screen and (max-width: 480px) {
            height: 80 / $setFs + rem;
        }
    }
}

.kv_iframe_yt {
    width: 100%;
    // height: calc(100% + 3.5625rem);
    height: 100%;
    box-sizing: border-box;
    object-fit: unset;
    background: #000;
    position: absolute;
    z-index: -1;
    // margin-top: -57 / $setFs + rem;
}

.scrolldown {
    // filter: drop-shadow(0 0 7px rgba(#000, .5));
    position: absolute;
    bottom: 30 / $setFs + rem;
    left: 120 / $setFs + rem;
    transform: translateX(-50%);
    animation: scrolldown_opacity 1.7s ease-out infinite;
    z-index: 1;

    @media screen and (max-width:950px) {
        display: none;
    }
}

.scrolldown_txt {
    font-size: 18 / $setFs + rem;
    font-family: $font1;
    // color: #fff;
    white-space: nowrap;
    line-height: 1;
    position: absolute;
    bottom: -4 / $setFs + rem;
    left: 100%;
    margin-left: 15 / $setFs + rem;
}

.scrolldown_arrow {
    width: 15 / $setFs + rem;
    animation: scrolldown_icon 1.7s ease-out infinite;
}

@keyframes scrolldown_icon {
    0% {
        transform: translateY(#{-10 / $setFs + rem});
    }

    55% {
        transform: translateY(#{10 / $setFs + rem});
    }

    100% {
        transform: translateY(#{-10 / $setFs + rem});
    }
}

@keyframes scrolldown_opacity {
    0% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.kv {
    // width: 113%;
    width: 100%;
    background-image: url('../../img/bg.jpg');
    background-size: 150px auto;
    animation: bg .4s infinite step-start both;
    position: absolute;
    top: 0%;
    // left: -6.5%;
    left: 0%;

    @media screen and (max-width: 950px) {
        // width: calc(100% - #{40 / $setFs + rem});
        width: 100%;
        top: 0;
        // left: 20 / $setFs + rem;
        left: 0;
    }
}

.track,
.letter,
.letter_txt,
.circle,
.main_obj,
.obj {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.track {
    width: 1121 / 1920 * 100%;
    z-index: 0;

    @media screen and (max-width: 480px) {
        width: 912 / 950 * 100%;
    }
}

.main_obj {
    width: 552 / 1920 * 100%;
    top: 60.5%;
    left: 53%;

    @media screen and (max-width: 480px) {
        width: 472 / 950 * 100%;
        top: 53.4%;
        left: 53%;
    }
}

.obj_1 {
    width: 291 / 1920 * 100%;
    top: 71%;
    left: 29%;

    @media screen and (max-width: 480px) {
        width: 259 / 950 * 100%;
        top: 73.2%;
        left: 24.5%;
        z-index: 2;
    }
}

.obj_2 {
    width: 381 / 1920 * 100%;
    top: 52%;
    left: 18%;

    @media screen and (max-width: 480px) {
        width: 346 / 950 * 100%;
        top: 51.8%;
        left: 18.4%;
    }
}

.obj_3 {
    width: 564 / 1920 * 100%;
    top: 24%;
    left: 26.5%;

    @media screen and (max-width: 480px) {
        width: 333 / 950 * 100%;
        top: 28%;
        left: 25.2%;
    }
}

.obj_4 {
    width: 321 / 1920 * 100%;
    top: 18.2%;
    left: 54%;

    @media screen and (max-width: 480px) {
        width: 236 / 950 * 100%;
        top: 18.5%;
        left: 60%;
    }
}

.obj_5 {
    width: 443 / 1920 * 100%;
    top: 29.7%;
    left: 73%;

    @media screen and (max-width: 480px) {
        width: 284 / 950 * 100%;
        top: 29.2%;
        left: 77.8%;
        z-index: 2;
    }
}

.obj_6 {
    width: 413 / 1920 * 100%;
    top: 47.5%;
    left: 83.5%;

    @media screen and (max-width: 480px) {
        width: 264 / 950 * 100%;
        top: 57%;
        left: 83.5%;
    }
}

.obj_7 {
    width: 367 / 1920 * 100%;
    top: 74.5%;
    left: 73.5%;

    @media screen and (max-width: 480px) {
        width: 322 / 950 * 100%;
        top: 76%;
        left: 68%;
    }
}

.letter {
    width: 70 / 1920 * 100%;

    @media screen and (max-width: 480px) {
        width: 70 / 950 * 100%;
    }
}

.letter_t {
    top: 15%;
    left: 43%;

    @media screen and (max-width: 480px) {
        top: 29.7%;
        left: 43.5%;
    }
}

.letter_f {
    top: 48.5%;
    left: 31.4%;

    @media screen and (max-width: 480px) {
        top: 42.2%;
        left: 83%;
    }
}

.letter_a {
    top: 58.5%;
    left: 68.3%;

    @media screen and (max-width: 480px) {
        top: 58.8%;
        left: 34%;
    }
}

.letter_m {
    top: 86.4%;
    left: 55%;

    @media screen and (max-width: 480px) {
        top: 64.5%;
        left: 65.5%;
    }
}


.letter_txt {
    width: 36 / 1920 * 100%;

    @media screen and (max-width: 480px) {
        width: 43 / 950 * 100%;
    }
}

.letter_1 {
    top: 26.5%;
    left: 44.2%;

    @media screen and (max-width: 480px) {
        top: 21.2%;
        left: 31%;
    }
}

.letter_2 {
    top: 35.5%;
    left: 58.7%;

    @media screen and (max-width: 480px) {
        top: 37.1%;
        left: 61.8%;
    }
}

.letter_3 {
    top: 53.5%;
    left: 39.5%;

    @media screen and (max-width: 480px) {
        top: 61.1%;
        left: 19.7%;
    }
}

.letter_4 {
    top: 76.2%;
    left: 56%;

    @media screen and (max-width: 480px) {
        top: 66.3%;
        left: 81%;
    }
}

.circle {
    border-radius: 100px;

    &.large {
        width: 119 / 1920 * 100vw;
        height: 119 / 1920 * 100vw;

        @media screen and (max-width: 480px) {
            width: 97 / 950 * 100vw;
            height: 97 / 950 * 100vw;
        }
    }

    &.small {
        width: 23 / 1920 * 100vw;
        height: 23 / 1920 * 100vw;

        @media screen and (max-width: 480px) {
            width: 22 / 950 * 100vw;
            height: 22 / 950 * 100vw;
        }
    }

    &.circle_1 {
        background: #ec6138;
        top: 65%;
        left: 37.6%;
        z-index: 0;

        @media screen and (max-width: 480px) {
            top: 79.5%;
            left: 31.5%;
        }
    }

    &.circle_2 {
        background: #e8c2c4;
        top: 57%;
        left: 21%;

        @media screen and (max-width: 480px) {
            top: 56.5%;
            z-index: 0;
        }
    }

    &.circle_3 {
        background: #59503a;
        top: 14.7%;
        left: 29.5%;

        @media screen and (max-width: 480px) {
            top: 21.6%;
            left: 15.5%;
        }
    }

    &.circle_4 {
        background: #355b87;
        top: 25.2%;
        left: 50.4%;

        @media screen and (max-width: 480px) {
            top: 21.5%;
            left: 51.5%;
        }
    }

    &.circle_5 {
        background: #d8c506;
        top: 16%;
        left: 71.3%;

        @media screen and (max-width: 480px) {
            top: 22.5%;
            left: 84.4%;
        }
    }

    &.circle_6 {
        background: #7a5e8e;
        top: 54.3%;
        left: 75.3%;

        @media screen and (max-width: 480px) {
            top: 56%;
            left: 72.5%;
            z-index: 0;
        }
    }

    &.circle_7 {
        background: #698649;
        top: 73%;
        left: 65.1%;

        @media screen and (max-width: 480px) {
            top: 73%;
            left: 56%;
            z-index: 0;
        }
    }

    &.circle_8 {
        background: #d8c506;
        top: 91.5%;
        left: 32.7%;

        @media screen and (max-width: 480px) {
            top: 91.5%;
            left: 14%;
        }
    }

    &.circle_9 {
        width: 22 / 1920 * 100vw;
        height: 22 / 1920 * 100vw;
        background: #7a5e8e;
        top: 80.5%;
        left: 16.2%;

        @media screen and (max-width: 480px) {
            width: 22 / 950 * 100vw;
            height: 22 / 950 * 100vw;
            top: 69%;
            left: 11%;
        }
    }

    &.circle_10 {
        background: #698649;
        top: 28.8%;
        left: 13.3%;

        @media screen and (max-width: 480px) {
            top: 14%;
            left: 40%;
        }
    }

    &.circle_11 {
        background: #59503a;
        top: 27%;
        left: 88.4%;
    }

    &.circle_12 {
        width: 26 / 1920 * 100vw;
        height: 26 / 1920 * 100vw;
        background: #ec6138;
        top: 37.5%;
        left: 80.6%;

        @media screen and (max-width: 480px) {
            width: 22 / 950 * 100vw;
            height: 22 / 950 * 100vw;
            top: 36%;
            left: 57%;
        }
    }

    &.circle_13 {
        background: #355b87;
        top: 81%;
        left: 86%;

        @media screen and (max-width: 480px) {
            top: 81%;
            left: 43.5%;
        }
    }

    &.circle_14 {
        width: 22 / 1920 * 100vw;
        height: 22 / 1920 * 100vw;
        background: #e8c2c4;
        top: 94%;
        left: 70%;

        @media screen and (max-width: 480px) {
            width: 21 / 950 * 100vw;
            height: 21 / 950 * 100vw;
            top: 65%;
            left: 57.5%;
        }
    }
}